/** 产品申请表单 */
<template>
  <el-form :class="['product-form',{'product-form-mobile':!$isPc}]" :model="form" :rules="$rules.common" ref="form"
    :label-width="$isPc?'':'90px'">

    <el-form-item prop="name" label="联系人">
      <el-input v-model="form.name" placeholder="请输入用户名称"> </el-input>
    </el-form-item>

    <el-form-item prop="phone" label="手机号">
      <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
    </el-form-item>

    <el-form-item prop="company_name" label="公司名称">
      <el-input v-model="form.company_name" placeholder="请输入公司名称"></el-input>
    </el-form-item>

    <el-form-item label="所在区域" prop="area" :rules="[{ required: true, message: '请选择所在地' }]">
      <el-select v-model="form.area" filterable placeholder="请选择所在地">
        <el-option v-for="item in provs" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="industry_type" label="行业类型">
      <el-select v-model="form.industry_type" filterable placeholder="请选择行业类型">
        <el-option v-for="item in industryTypes" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="position" label="职位">
      <el-input v-model="form.position" placeholder="请输入职位"></el-input>
    </el-form-item>

    <el-form-item prop="apply_credit" label="申请额度">
      <el-input v-model="form.apply_credit" placeholder="请输入申请额度"></el-input>
    </el-form-item>

    <el-form-item prop="make_date" label="用款时间">
      <el-date-picker type="date" v-model="form.make_date" :picker-options="pickerOptions0" value-format="yyyy-MM-dd"
        placeholder="选择日期">
      </el-date-picker>
    </el-form-item>
    <el-form-item class="form-submit">
      <el-button class="theme-back" type="primary" :loading="isLoading" @click="login('form')">提交</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { applyProduct } from '@/api/product'
export default {
  name: 'ProductForm',
  props: ['id'],
  data () {
    return {
      isLoading: false, // 注册加载状态
      form: {
        product_id: this.id || this.$route.query.id,
        name: '',
        phone: '',
        company_name: '',
        area: '',
        industry_type: '',
        position: '',
        apply_credit: '',
        make_date: ''
      },
      // 省份数据
      provs: [
        {
          label: '北京',
          value: '北京'
        },
        {
          label: '天津',
          value: '天津'
        },
        {
          label: '河北',
          value: '河北',

        },
        {
          label: '山西',
          value: '山西',

        },
        {
          label: '内蒙古',
          value: '内蒙古',

        },
        {
          label: '辽宁',
          value: '辽宁',

        },
        {
          label: '吉林',
          value: '吉林',

        },
        {
          label: '黑龙江',
          value: '黑龙江',

        },
        {
          label: '上海',
          value: '上海',

        },
        {
          label: '江苏',
          value: '江苏',

        },
        {
          label: '浙江',
          value: '浙江',
        },
        {
          label: '安徽',
          value: '安徽',
        },
        {
          label: '福建',
          value: '福建',
        },
        {
          label: '江西',
          value: '江西',
        },
        {
          label: '山东',
          value: '山东',
        },
        {
          label: '河南',
          value: '河南',
        },
        {
          label: '湖北',
          value: '湖北',
        },
        {
          label: '湖南',
          value: '湖南',
        },
        {
          label: '广东',
          value: '广东',
        },
        {
          label: '海南',
          value: '海南',
        },
        {
          label: '重庆',
          value: '重庆'
        },
        {
          label: '四川',
          value: '四川',
        },
        {
          label: '贵州',
          value: '贵州',
        },
        {
          label: '云南',
          value: '云南',
        },
        {
          label: '西藏',
          value: '西藏',
        },
        {
          label: '陕西',
          value: '陕西',
        },
        {
          label: '甘肃',
          value: '甘肃',
        },
        {
          label: '青海',
          value: '青海',
        },
        {
          label: '宁夏',
          value: '宁夏',
        },
        {
          label: '新疆',
          value: '新疆',
        },
        {
          label: '台湾',
          value: '台湾',
        },
        {
          label: '香港',
          value: '香港',
        },
        {
          label: '澳门',
          value: '澳门',
        }
      ],
      // 行业类型
      industryTypes: [
        { label: '农、林、牧、渔业', value: '农、林、牧、渔业' },
        { label: '采矿业 ', value: '采矿业 ' },
        { label: '制造业', value: '制造业' },
        { label: '电力、然气及水的生产和供应业 ', value: '电力、然气及水的生产和供应业 ' },
        { label: '建筑业', value: '建筑业' },
        { label: '交通运输、仓储和邮政业', value: '交通运输、仓储和邮政业' },
        { label: '信息传输、计算机服务和软件业', value: '信息传输、计算机服务和软件业' },
        { label: '批发和零售业', value: '批发和零售业' },
        { label: '住宿和餐饮业', value: '住宿和餐饮业' },
        { label: '金融业', value: '金融业' },
        { label: '房地产业', value: '房地产业' },
        { label: '租赁和商务服务业', value: '租赁和商务服务业' },
        { label: '科学研究、技术服务和地质勘查业', value: '科学研究、技术服务和地质勘查业' },
        { label: '水利、环境和公共设施管理业', value: '水利、环境和公共设施管理业' },
        { label: '居民服务和其他服务业', value: '居民服务和其他服务业' },
        { label: '教育', value: '教育' },
        { label: '卫生、社会保障和社会福利业', value: '卫生、社会保障和社会福利业' },
        { label: '文化、体育和娱乐业', value: '文化、体育和娱乐业' },
        { label: '公共管理与社会组织', value: '公共管理与社会组织' },
        { label: '国际组织', value: '国际组织' }
      ],
      // 日期选择器限制只能选今天以及今天之后的日期
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的 
        }
      },
    }
  },
  computed: {
    ...mapGetters([
      'userToken',
    ]),
    showApply () {
      return this.$store.getters.showApply//需要监听的数据
    }
  },
  watch: {
    showApply (newVal, oldVal) {
      this.$refs.form.resetFields()
    },
    id (newVal) {
      this.form.product_id = newVal
    }
  },
  methods: {
    // 提交
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let newForm = {}
            for (const key in this.form) {
              if (this.form[key]) {
                if (key == 'name') {
                  newForm['contact_name'] = this.form[key]
                } else if (key == 'phone') {
                  newForm['contact_phone'] = this.form[key]
                } else {
                  newForm[key] = this.form[key]
                }
              }
            }
            let { data, code, msg } = await applyProduct(newForm)
            if (code == 200) {
              this.$message.success(this.userToken ? msg + '，可在个人中心查看' : msg);
              this.$emit('handleClose', () => { this.$refs.form.resetFields() })
            } else {
              this.$message.error(msg)
            }
          } catch (error) { }
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy () {
    // 重置form表单
    this.$refs.form.resetFields()
  },
}
</script>
<style lang="scss" scoped>
.product-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-form-item {
    width: 48%;
    &:last-child {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }
    .el-input,
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    .el-date-editor {
      .el-input {
        width: 100% !important;
        .el-input__inner {
          padding-left: 15px;
        }
        .el-input__prefix {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .product-form-mobile {
    padding: 0 10px;
    .el-form-item {
      width: 80%;
      margin: 10px auto;
      &.form-submit {
        /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>