/** 金融超市 */
<template>
  <div class="product">
    <section class="select-con  justify-between align-center" :class="[$isPc?'ptb-30':'ptb-20']">
      <div v-if="$isPc" class="select-list">
        <div class="flex">
          <label class="select-label">最高担保额度</label>
          <el-radio-group v-model="filtersData.lines" @change="handlefilter">
            <el-radio v-for="(item,index) in linesList" :key="index" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="flex">
          <label class="select-label">融资渠道</label>
          <el-checkbox-group v-model="checkedCities" @change="handlefilter">
            <el-checkbox v-for="(item,index) in channelList" :key="index" :label="item">{{item}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <Search @searchBtn="searchBtn" />
    </section>
    <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="productData" :loading="loading" :total="pageTotal" :pageSize="20" :resetPage="resetPage"
        @page="changePage">
        <template v-slot="{item}">
          <div :class="['product-info', 'flex-column', $isPc?'justify-between':'justify-around']">
            <div :class="[$isPc?'ptb-30':'pa-10']">
              <div :class="['product-img', 'flex-center',$isPc?'mb-30':'mb-10' ]">
                <el-image :src="$base+item.product_logo" fit="contain"></el-image>
              </div>
              <p class="product-name flex-center"><strong>{{item.product_name}}</strong></p>
            </div>
            <div class="product-btn justify-around align-center">
              <router-link :to="{ path: '/product_details', query: { id: item.id } }">
                <el-button type="primary" plain>了解详情 </el-button>
              </router-link>
              <el-button v-if="$isPc" type="primary" @click="applyBtn(item)">立即申请</el-button>
            </div>
          </div>
        </template>
      </List>
    </LoadMore>
    <ProductApply :id="id" />
  </div>
</template>
<script>
import { getProduct } from '@/api/product'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
import ProductApply from './apply.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Product',
  components: {
    LoadMore,
    List,
    Search,
    ProductApply
  },
  data () {
    return {
      id: '',
      filtersData: {
        lines: 0,
        channel: '不限',
        keywords: '',
        page: 1,
        limit: 20
      },
      checkedCities: ['不限'],//已选区域
      // 最高担保额度
      linesList: [
        { value: 0, label: '不限' },
        { value: 100, label: '100万及以下' },
        { value: 300, label: '300万及以下' },
        { value: 500, label: '500万及以下' },
        { value: 1000, label: '1000万及以下' },
        { value: 1001, label: '1000万以上' },
      ],
      // 融资渠道
      channelList: [
        '不限', '银行', '保险', '担保', '租赁', '小贷', '投资资金'
      ],
      pageTotal: 0,
      productData: [],
      loading: false,//正在加载
      finished: false, // 加载完成
      isReset: false,//是否重置数据
      resetPage: false,//分页重置
    }
  },
  mounted () {
    this.onLoadMore()
  },
  methods: {
    ...mapActions([
      'setShowApply',
    ]),
    async getProduct (filtersData) {
      try {
        let { data, total } = await getProduct(filtersData)
        this.productData = this.$isPc ? data : [...this.productData, ...data]
        this.pageTotal = total
        this.isReset = false
        this.loading = false;
      } catch (error) { }
    },
    // 筛选项切换
    handlefilter (val) {
      if (val instanceof Array) {
        if (val[val.length - 1] == '不限' || !val.length) {
          this.checkedCities = ['不限']
        } else {
          let ind = val.findIndex(i => i == '不限')
          ind != -1 && this.checkedCities.splice(ind, 1)
        }
      }
      this.isReset = true
      this.onLoadMore()
    },
    // 搜索
    searchBtn (val) {
      this.filtersData.keywords = val
      this.isReset = true
      this.onLoadMore()
    },
    // 整合查询数据
    async setFiltersData () {
      if (this.isReset) {
        this.pageTotal = 0
        this.productData = []
        this.filtersData.page = 1
        this.resetPage = !this.resetPage
        this.loading = true
        this.finished = false
      }

      if (!this.filtersData.keywords) {
        delete this.filtersData.keywords
      }
      let newFiltersData = JSON.parse(JSON.stringify(this.filtersData));
      if (this.checkedCities.length) {
        newFiltersData.channel = this.checkedCities
      }
      if (newFiltersData.lines == 0) {
        delete newFiltersData.lines
      }
      if (newFiltersData.channel == '不限') {
        delete newFiltersData.channel
      }
      await this.getProduct(newFiltersData)
    },
    // 切换分页
    changePage (page) {
      this.filtersData.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.setFiltersData()
      done && done();
      if (this.productData.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.filtersData.page += 1
    },
    // 立即申请
    applyBtn (item) {
      this.id = item.id
      this.setShowApply(true)
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/.select-con {
  .select-list {
    & > div {
      margin: 10px 0;
    }
    .select-label {
      width: 84px;
      text-align: right;
      font-size: 14px;
      color: #b3bcd0;
      margin-top: 2px;
    }
    .el-radio-group,
    .el-checkbox-group {
      .el-radio,
      .el-checkbox {
        margin-right: 0;
        .el-radio__input,
        .el-checkbox__input {
          visibility: hidden;
        }
        .el-radio__label,
        .el-checkbox__label {
          font-size: 16px;
        }
      }
    }
  }
}
/deep/.list {
  flex-direction: row;
  .item {
    padding: 0;
    width: 24%;
    height: auto;
    border-bottom: none;
    margin-bottom: 15px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  /deep/.list {
    .item {
      width: 48%;
    }
  }
}
</style>